* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.4;
}
@font-face {
  font-family: "SfProDisplay";
  src: local("SfProDisplay"),
    url("./fonts/SFPRODISPLAYREGULAR.OTF") format("truetype");
  font-family: "roboto";
  src: local("roboto"), url("./fonts/Roboto-Regular.ttf") format("truetype");
}
/* declare variables */
:root {
  --primary: #019eb2;
  --box-shadow-light: 0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  --border-light-gray: 1px solid rgba(0, 0, 0, 0.38);
  --border-raius-light: 4px;
  --sf-pro-display: "SfProDisplay";
  --black-clr: #000000;
  --light-secondary: #fafafa;
  --pink: #d0347e;
  --roboto: "roboto";
}
.navActive {
  color: var(--pink) !important;
}
/* global styling */
.sf-font {
  font-family: "SfProDisplay";
}
.roboto-font {
  font-family: "roboto";
}
a {
  text-decoration: none;
}
.page-section {
  display: flex;
  width: 100%;
}
.page-section .page-content {
  padding: 2rem 1.3rem;
}
.tabs-styling {
  padding: 6px 3%;
  font-size: 16px;
}
.buttonsss {
  margin-top: 30px;
}
.search-input {
  width: 100%;
  margin-right: 12px;
  height: 100%;
  /* border-radius: 5px; */
  border: none;
  outline: none;
  font-family: var(--roboto);
  font-size: 17px;
  font-weight: 400;
}
.search-input::placeholder {
  color: #ddd;
}
.bc-btn-outline {
  font-family: var(--roboto);
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 1.25px !important;
  text-transform: uppercase !important;
  color: var(--primary);
  background: white;
  height: 45px !important;
  cursor: pointer;
  transition: all 0.3s ease;
}
.add_note input {
  padding-top: 17px !important;
  padding-bottom: 15px !important;
}

.add_note input::placeholder {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 400;
}
.bc-btn-outline:hover {
  background-color: var(--primary) !important;
  color: white;
}
.tableLink {
  color: #2196f3;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}
.tab_btns {
}
.tab_btns > button {
  border: 1px solid #ddd !important;
}
.tab_btns > button.active {
  text-decoration: none !important;
  background-color: rgba(223, 248, 251, 255) !important;
}

.tab_btns button:first-child {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.tab_btns button:nth-child(2) {
  border-radius: 0px !important;
}
.tab_btns button:last-child {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}
.variable-input-require {
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 1.7rem;
  margin-top: 0.7rem;
}
.about_body {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.about_body .about_img_circle {
  width: 174px;
  height: 174px;
  border-radius: 50%;
  background: #c4c4c4;
  background-size: cover !important;
}
.about_body .about_body_card {
  flex: 1;
  background: #e8e8e8;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  padding: 10px;
  min-width: 260px;
}
.about_body_card .company_info h4 {
  font-size: 24px;
  color: #000000;
  text-decoration: underline;
  text-transform: capitalize;
  letter-spacing: 0.15px;
}
.company_info .info_para {
  letter-spacing: 0.15px;
  font-size: 20px;
  color: #000000;
}
.company_info .enabled {
  color: #1fbad2;
  font-size: 20px;
  letter-spacing: 0.15px;
  cursor: pointer;
}
.company_info .enabledWithoutPointer {
  color: #1fbad2;
  font-size: 20px;
  letter-spacing: 0.15px;
}
.company_info .disabled {
  color: #d0347e;
  font-size: 20px;
  letter-spacing: 0.15px;
  cursor: pointer;
}
.available_service_card {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1.4rem;
  padding: 16px;
  background: #e8e8e8;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
}
.available-services {
  padding-left: 3rem;
}
.addImg {
  width: 85px;
  height: 85px;
  background-color: #c4c4c4;
  border-radius: 50%;
  display: block;
  cursor: pointer;
  overflow: hidden;
}
.group {
}
.group {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  max-width: 270px;
  margin: 0 auto;
  margin-bottom: 1rem;
}
.group .btn_label {
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
.group .btn_group button {
  padding: 10px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #979797;
  background-color: transparent;
  border: 1px solid #ddd;
  width: 112px;
  cursor: pointer;
  transition: all 0.4s ease;
}
.group .btn_group button:first-child {
  border-radius: 100px 0px 0px 100px;
}
.group .btn_group button:last-child {
  border-radius: 0px 100px 100px 0px;
}
.group .btn_group button.active {
  background: #fdecdf;
  border: 1px solid #fdaa63;
  color: #000000;
}

#message::placeholder {
  color: black; /* Change this to your desired color */
  opacity: 1; /* Ensures the color is fully applied */
}

/* .MuiTableCell-root MuiTableCell-body{
   background-color: red !important;
} */
